// src/constants/chainConstants.js

/**
 * A mapping of chain IDs to their corresponding names.
 * This object provides a quick lookup for converting numeric chain IDs to human-readable chain names.
 *
 * @type {Object.<string, string>}
 */
export const chains = {
    "42161": "ARBITRUM",
    "1313161554": "AURORA",
    "43114": "AVALANCHE",
    "8453": "BASE",
    "56": "BSC",
    "42220": "CELO",
    "1116": "CORE",
    "250": "FANTOM",
    "122": "FUSE",
    "196": "XLAYER",
    "314": "FILECOIN",
    "100": "GNOSIS",
    "59144": "LINEA",
    "1337": "LOCAL",
    "1": "MAINNET",
    "5000": "MANTLE",
    "10": "OPTIMISM",
    "137": "POLYGON",
    "1101": "POLYGONZKEVM",
    "108": "THUNDERCORE",
    "324": "ZKSYNC",
    "534352": "SCROLL",
    "169": "MANTA",
    "13371": "IMMUTABLE",
    "81457": "BLAST",
    "34443": "MODE",
    "592": "ASTAR",
    "3776": "ASTARZKEVM",
    "30": "ROOTSTOCK",
    "167000": "TAIKO",
    "1329": "SEI",
    "1284": "MOONBEAM",
    "2046399126": "SKALE",
    "252": "FRAXTAL"
};

/**
 * Retrieves the chain name for a given chain ID.
 *
 * @param {string} chainId - The chain ID to look up.
 * @returns {string} The chain name if found, or a string indicating an unknown chain.
 */
export const getChainName = (chainId) => {
    return chains[chainId].toLowerCase() || `Unknown (${chainId})`;
};