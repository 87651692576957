// src/merklCampaignsComponents/TableFilters.jsx
import React from 'react';
import { Input, Select, Option } from '@mui/joy';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";

export const DefaultColumnFilter = ({
                                        column: { filterValue, preFilteredRows, setFilter },
                                    }) => {
    const count = preFilteredRows.length

    return (
        <Input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`...`}
            size="sm"
            onClick={(e) => e.stopPropagation()}
        />
    )
}

export const ColumnFilterWithCount = ({
                                        column: { filterValue, preFilteredRows, setFilter },
                                    }) => {
    const count = preFilteredRows.length

    return (
        <Input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`${count}`}
            size="sm"
            onClick={(e) => e.stopPropagation()}
        />
    )
}

export const FavoriteFilter = ({ column: { filterValue, setFilter } }) => {
    return (
        <Select
            value={filterValue || 'all'}
            onChange={(_, newValue) => setFilter(newValue)}
            size="sm"
        >
            <Option value="all">All</Option>
            <Option value="favorite">Favorite</Option>
            <Option value="not-favorite">Not Favorite</Option>
        </Select>
    );
};

export const CampaignFilter = ({ column: { filterValue, setFilter } }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                value={filterValue ? moment.unix(filterValue) : null}
                onChange={(newValue) => {
                    setFilter(newValue ? newValue.unix() : undefined);
                }}
                slotProps={{
                    textField: {
                        size: 'small',
                        placeholder: 'Filter by start date',
                    }
                }}
                format="YYYY-MM-DD HH:mm"
            />
        </LocalizationProvider>
    );
};

export const campaignStartFilterFn = (rows, id, filterValue) => {
    return rows.filter(row => {
        const rowValue = row.values[id];
        return filterValue ? rowValue >= filterValue : true;
    });
};