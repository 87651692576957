// src/merklCampaignsComponents/formatters.jsx
import React from 'react';
import { Tooltip } from '@mui/joy';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { formatTokenDisplay, convertFromWei } from '../utils/tokenUtils';

export const formatDailyRewardTokens = (tokens, chainId, rewardUsd) => {
    if (!Array.isArray(tokens)) return '';

    return (
        <div>
            <div>$ {(rewardUsd !== undefined && typeof rewardUsd === 'number') ? rewardUsd.toFixed(2) : " - "}</div>
            {tokens.map((token, index) => (
                <div key={index} className="text-xs">{formatTokenDisplay(convertFromWei(token.amount), token.symbol)}</div>
            ))}
        </div>
    );
};

export const formatCampaigns = (campaigns, chainId, tokenInfo) => {
    if (!Array.isArray(campaigns) || !tokenInfo) return 'N/A';
    return campaigns.map(campaign => {
        const rewardDays = (campaign.endTimestamp - campaign.startTimestamp) / (60 * 60 * 24);
        const dailyReward = convertFromWei(campaign.amount) / rewardDays;
        const tokenData = tokenInfo.tokens[chainId]?.[campaign.rewardToken];
        const rewardToken = tokenData ? tokenData.symbol : campaign.rewardToken.slice(0, 4);
        const startDate = new Date(campaign.startTimestamp * 1000).toLocaleString('en-GB', { hour12: false, timeZone: 'Europe/Riga' });
        const endDate = new Date(campaign.endTimestamp * 1000).toLocaleString('en-GB', { hour12: false, timeZone: 'Europe/Riga' });

        let upcoming = false;

        if (new Date(campaign.endTimestamp * 1000) < new Date()){
            return ;
        }

        if (new Date(campaign.startTimestamp * 1000) > new Date()){
            upcoming = true;
        }

        const hasBlacklist = campaign.campaignParameters?.blacklist?.length > 0;
        const hasWhitelist = campaign.campaignParameters?.whitelist?.length > 0;

        const tooltipContent = (
            <div>
                {hasWhitelist && (
                    <div>
                        <strong>Whitelist:</strong>
                        <ul>
                            {campaign.campaignParameters.whitelist.map((address, index) => (
                                <li key={index}>{address}</li>
                            ))}
                        </ul>
                    </div>
                )}
                {hasBlacklist && (
                    <div>
                        <strong>Blacklist:</strong>
                        <ul>
                            {campaign.campaignParameters.blacklist.map((address, index) => (
                                <li key={index}>{address}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        );

        const restrictionIcon = (hasBlacklist || hasWhitelist) ? (
            <Tooltip title={tooltipContent} arrow>
                <ErrorOutlineIcon color="warning" fontSize="small" />
            </Tooltip>
        ) : null;

        return (
            <div key={(campaign.campaignId + campaign.mainParameter + campaign.rewardToken)} className="mb-2">
                {formatTokenDisplay(dailyReward, rewardToken, tokenData?.logoURI)}
                <span className="ml-2 text-xs">
                    {restrictionIcon} <b>{upcoming?"UPCOMING":""}</b> {startDate} - {endDate}
                </span>
            </div>
        );
    });
};

export const formatAprBreakdown = (aprData) => {
    if (!aprData || typeof aprData.breakdown !== 'object' || Object.keys(aprData.breakdown).length === 0) {
        return { formattedApr: aprData.total + '%', tooltip: 'No APR breakdown available' };
    }

    const breakdownArray = Object.entries(aprData.breakdown)
        .map(([label, data]) => ({ label, value: data.value }))
        .sort((a, b) => b.value - a.value);

    const top3 = breakdownArray.slice(0, 3);
    const remainingCount = breakdownArray.length - 3;

    const formattedApr = (
        <div>
            <div>{aprData.total}%</div>
            {top3.map((item, index) => (
                <div key={index} className="text-xs truncate w-40">{item.value.toFixed(2)}% : {item.label}</div>
            ))}
            {remainingCount > 0 && <div className="text-xs">+ {remainingCount} more...</div>}
        </div>
    );

    const tooltip = (
        <div>
            {breakdownArray.map((item, index) => (
                <div key={index}>{item.value.toFixed(2)}% : {item.label}</div>
            ))}
        </div>
    );

    return { formattedApr, tooltip };
};