// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainMenu from './MainMenu';
import MerklCampaigns from './MerklCampaigns';
import Strategy from './Strategy';
import UserWallets from './UserWallets';
import MerklRewards from './MerklRewards';
import Login from './components/Login';
import Signup from './components/Signup';
import { getCurrentUser } from './services/appwriteService';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkUser = async () => {
            const currentUser = await getCurrentUser();
            setUser(currentUser);
            setLoading(false);
        };
        checkUser();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router>
            <div className="App flex flex-col h-screen">
                <header className="flex-shrink-0">
                    <MainMenu user={user} setUser={setUser} />
                </header>
                <main className="flex-grow overflow-hidden">
                    <Routes>
                        <Route path="/login" element={user ? <Navigate to="/" replace /> : <Login setUser={setUser} />} />
                        <Route path="/signup" element={user ? <Navigate to="/" replace /> : <Signup />} />
                        <Route path="/" element={user ? <MerklCampaigns /> : <Navigate to="/login" replace />} />
                        <Route path="/strategy" element={user ? <Strategy /> : <Navigate to="/strategy" replace />} />
                        <Route path="/user-wallets" element={user ? <UserWallets /> : <Navigate to="/login" replace />} />
                        <Route path="/merkl-rewards" element={user ? <MerklRewards /> : <Navigate to="/login" replace />} />
                    </Routes>
                </main>
            </div>
        </Router>
        </LocalizationProvider>
    );
}

export default App;