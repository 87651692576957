// src/utils/tokenUtils.js
import React from 'react';

/**
 * Formats a token amount with its image and symbol
 * @param {number} amount - The token amount
 * @param {string} symbol - The token symbol
 * @param {string} imageUrl - The URL of the token image
 * @returns {JSX.Element} Formatted token display
 */
export const formatTokenDisplay = (amount, symbol, imageUrl) => {
    // Format the amount with thousand separators and 4 decimal places
    const formattedAmount = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
    }).format(amount);

    return (
        <span className="flex items-center">
      {imageUrl && (
          <img
              src={imageUrl}
              alt={symbol}
              className="w-5 h-5 mr-1 inline-block"
          />
      )}
            <span>{formattedAmount} {symbol}</span>
    </span>
    );
};

/**
 * Converts a wei value to its decimal representation
 * @param {string|number} value - The value in wei
 * @returns {number} The value in decimal form
 */
export const convertFromWei = (value) => {
    return parseFloat(value) / 1e18;
};