// src/merklCampaignsComponents/TableColumns.jsx
import React, { useState, useEffect } from 'react';
import { IconButton, Option, Select } from '@mui/joy';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
    CampaignFilter,
    campaignStartFilterFn,
    ColumnFilterWithCount,
    DefaultColumnFilter,
    FavoriteFilter
} from './TableFilters';
import { formatAprBreakdown, formatCampaigns } from './formatters';
import moment from "moment";
import {
    listUserStrategyNamesMerkl,
    addUserStrategyCampaignMerkl,
    deleteUserStrategyCampaignMerkl,
    getUserStrategyCampaignsMerkl
} from "../services/appwriteService";

const userStrategies = await listUserStrategyNamesMerkl();

const useUserStrategies = () => {
    const [userStrategies, setUserStrategies] = useState([]);

    useEffect(() => {
        const fetchUserStrategies = async () => {
            try {
                const strategies = await listUserStrategyNamesMerkl();
                setUserStrategies(strategies);
            } catch (error) {
                console.error('Error fetching user strategies:', error);
            }
        };

        fetchUserStrategies();
    }, []);

    return userStrategies;
};
// Helper function to save the selected strategy for an opportunity
const saveStrategyForOpportunity = async (opportunityId, strategyNameId) => {
    try {
        // First, remove any existing strategy for this opportunity
        const existingStrategies = await getUserStrategyCampaignsMerkl(strategyNameId, opportunityId);
        for (const strategy of existingStrategies) {
            await deleteUserStrategyCampaignMerkl(strategy.$id);
        }
        // Then, add the new strategy
        if(strategyNameId) {
            await addUserStrategyCampaignMerkl(strategyNameId, opportunityId);
        }
    } catch (error) {
        console.error('Error saving strategy for opportunity:', error);
    }
};

export const getColumns = (tokenInfo, favorites, handleFavoriteToggle) => [
    {
        Header: 'Favorite',
        accessor: 'isFavorite',
        maxWidth: 80,
        Filter: FavoriteFilter,
        Cell: ({ row }) => (
            <IconButton
                onClick={() => handleFavoriteToggle(row.original.id)}
                color={row.original.isFavorite ? 'warning' : 'neutral'}
                variant="plain"
            >
                {row.original.isFavorite ? <StarIcon /> : <StarBorderIcon />}
            </IconButton>
        ),
        filter: (rows, id, filterValue) => {
            if (filterValue === 'all') return rows;
            return rows.filter(row =>
                filterValue === 'favorite' ? row.original.isFavorite : !row.original.isFavorite
            );
        }
    },
    {
        Header: 'Strategy',
        accessor: 'strategyName',
        Filter: DefaultColumnFilter,
        maxWidth: 100,
        Cell: ({ row }) => {
            const [selectedStrategy, setSelectedStrategy] = useState(row.original.strategyName);

            const handleStrategyChange = async (event, newValue) => {
                setSelectedStrategy(newValue);
                const selectedStrategyId = userStrategies.find(strategy => strategy.name === newValue)?.$id;
                await saveStrategyForOpportunity(row.original.id, selectedStrategyId);
                row.original.strategyName = newValue;

            };

            return (
                <Select
                    value={selectedStrategy}
                    onChange={handleStrategyChange}
                    size="sm"
                    variant="plain"
                >
                    <Option value="">None</Option>
                    {userStrategies.map(strategy => (
                        <Option key={row.original.id+strategy.$id} value={strategy.name}>
                            {strategy.name}
                        </Option>
                    ))}
                </Select>
            );
        },
    },
    { Header: 'Chain', accessor: 'chainName', Filter: DefaultColumnFilter, maxWidth: 100, },
    { Header: 'Name', accessor: 'name', Filter: DefaultColumnFilter, maxWidth: 150,
        Cell: ({ row }) => {
        let sushiURL = null;
        let poolAddress =  row.original.id.split("_").pop();
        if (row.original.name.substring(0,9) === "SushiSwap"){
            sushiURL = `https://www.sushi.com/pool/${row.original.chainId}%3A${poolAddress}`;
        }
        return (
            <div className="">
                {row.original.name} {sushiURL ? <a href={sushiURL} target={"_blank"}>(Sushi)</a> : null}<br/>
                {row.original.id}
            </div>
        )}, },
    { Header: 'Token Icons', accessor: 'tokenIcons', Filter: DefaultColumnFilter, maxWidth: 300, },
    { Header: 'Action', accessor: 'action', Filter: DefaultColumnFilter, maxWidth: 100, },
    { Header: 'Status', accessor: 'status', Filter: DefaultColumnFilter, maxWidth: 100, },
    {
        Header: 'APR',
        accessor: 'apr',
        sortType: (rowA, rowB, columnId, desc) => {
            const a = parseFloat(rowA.values[columnId].total);
            const b = parseFloat(rowB.values[columnId].total);
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        },
        Filter: DefaultColumnFilter,
        Cell: ({ value }) => {
            const { formattedApr, tooltip } = formatAprBreakdown(value);
            return (
                <div className="relative group">
                    {formattedApr}
                    <div className="hidden group-hover:block absolute z-10 p-2 bg-gray-100 border rounded shadow-lg">
                        {tooltip}
                    </div>
                </div>
            );
        }
    },
    { Header: 'TVL', accessor: 'tvl', Filter: DefaultColumnFilter },
    {
        Header: 'Campaigns',
        accessor: row => formatCampaigns(row.campaigns, row.chainId, tokenInfo),
        Filter: ColumnFilterWithCount,
        Cell: ({ value }) => (
            <div>
                {value}
            </div>
        ),
        disableSortBy: true
    },
    {
        Header: 'Daily Rewards',
        accessor: 'dailyrewards',
        sortType: (rowA, rowB, columnId, desc) => {
            const a = parseFloat(rowA.values[columnId]);
            const b = parseFloat(rowB.values[columnId]);
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        },
        Filter: DefaultColumnFilter,
        Cell: ({ value }) => (
            <div className="flex flex-col">
                {value}
            </div>
        )
    },
    {
        Header: 'Campaign Start',
        accessor: 'campaignStart',
        Filter: CampaignFilter,
        filter: 'campaignStart', // This should match the key in filterTypes
        maxWidth: 200,
        Cell: ({ value }) => (
            <div className="">
                {moment.unix(value).format('YYYY-MM-DD HH:mm')}
            </div>
        ),
    },
];