// src/Snapshots.jsx
import React, {useEffect, useState} from 'react';
import {
    Button,
    DialogContent,
    DialogTitle, FormControl, FormLabel,
    Grid,
    IconButton,
    Input,
    ModalDialog,
    Sheet, Stack,
    Table,
    Typography
} from "@mui/joy";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/joy/Modal';
import DialogActions from '@mui/joy/DialogActions';

import {
    createUserStrategyNamesMerkl, deleteUserStrategyNamesMerkl,
    fetchUserWalletAddresses,
    getAllUserStrategyCampaignsMerkl,
    listUserStrategyNamesMerkl, updateUserStrategyNameMerkl
} from "./services/appwriteService";

// Placeholder component for the Snapshots page
const Strategy = () => {
    const [strategies, setStrategies] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newStrategyName, setNewStrategyName] = useState([]);
    const [editingStrategy, setEditingStrategy] = useState(null);

    useEffect(() => {
        fetchStrategies();
    }, []);

    const fetchStrategies = async () => {
        try {
            const fetchedStrategies = await listUserStrategyNamesMerkl();
            setStrategies(fetchedStrategies);
        } catch (error) {
            console.error('Error fetching strategies:', error);
        }
    };

    const addStrategy = async (name) => {
        try {
            setNewStrategyName("");
            await createUserStrategyNamesMerkl(name);
            await fetchStrategies();
        } catch (error) {
            console.error('Error fetching strategies:', error);
        }
    }

    const deleteStrategy = async (strategy) => {
        try {
            await deleteUserStrategyNamesMerkl(strategy.$id);
            await fetchStrategies();
        } catch (error) {
            console.error('Error fetching strategies:', error);
        }
    }

    const renameStrategy = async (strategy, name) => {
        try {
            await updateUserStrategyNameMerkl(strategy.$id, name);
            await fetchStrategies();
            setIsModalOpen(false);
            setEditingStrategy(null);

        } catch (error) {
            console.error('Error fetching strategies:', error);
        }
    }


    return (
        <div className="container mx-auto p-4">
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={6}>
                    <Typography level="h3">Strategies</Typography>
                </Grid>
                <Grid xs={6} className="text-right">
                    <Input
                        size="sm"
                        value={newStrategyName}
                        onChange={e => setNewStrategyName(e.target.value)}
                        endDecorator={<Button
                            variant="outlined"
                            onClick={() => addStrategy(newStrategyName)}
                        >Add</Button>}/>
                </Grid>
            </Grid>

            <Sheet>
            <Table>
                <thead>
                <tr>
                    <th>Strategy name</th>
                    <th style={{ width: '64px' }}>Actions</th>
                </tr>
                </thead>

                <tbody>
                {strategies.map(strategy => (
                    <tr key={strategy.$id}>
                        <td>
                            <Typography>{strategy.name}</Typography>
                        </td>

                        <td>
                            <IconButton onClick={() => {
                                setEditingStrategy(strategy);
                                setIsModalOpen(true);
                            }}>
                                <EditIcon />
                            </IconButton>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            </Sheet>

            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalDialog>
                    <DialogTitle>Edit or delete strategy</DialogTitle>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            renameStrategy(editingStrategy, event.target.elements.strategyName.value);
                        }}
                    >

                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input name="strategyName" autoFocus required defaultValue={editingStrategy ? editingStrategy.name : ''}/>
                        </FormControl>

                        <DialogActions>
                            <Button type="submit" variant="outline">Save</Button>
                            <Button color="danger" variant="plain" onClick={() => deleteStrategy(editingStrategy)}>Delete</Button>
                        </DialogActions>
                    </form>
                </ModalDialog>
            </Modal>
        </div>
    );
};

export default Strategy;