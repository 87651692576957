// src/MerklRewards.jsx
import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import { Typography, IconButton, Input} from '@mui/joy';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
    getUserWalletsForMerklRewards,
    getLatestMerklReward,
    getMerklRewardsFile,
    fetchMerklData,
    fetchMerklLatestApiCampaigns,
    fetchMerklLatestTokenInfo
} from './services/appwriteService';
import { getChainName } from './constants/chainConstants';
import SyncIcon from "@mui/icons-material/Sync";

// Default column filter component
const DefaultColumnFilter = (
    {column: { filterValue, preFilteredRows, setFilter },}) => {
    const count = preFilteredRows.length;
    return (
        <Input
            value={filterValue || ''}
            onChange={e => {setFilter(e.target.value || undefined);}}
            placeholder={`...`}
            size="sm"
            onClick={(e) => e.stopPropagation()}
        />
    );
};

const MerklRewards = () => {
    const [rewards, setRewards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [campaignData, setCampaignData] = useState({});
    const [tokenInfo, setTokenInfo] = useState({});

    useEffect(() => {
        fetchAllData();
    }, []);

    const fetchAllData = async () => {
        try {
            setLoading(true);
            const [merklData, userWallets, tokenInfo] = await Promise.all([
                fetchMerklLatestApiCampaigns(),
                getUserWalletsForMerklRewards(),
                fetchMerklLatestTokenInfo()
            ]);

            setCampaignData(merklData.campaignData);
            setTokenInfo(merklData.tokenInfo);

            const allRewards = await processUserWallets(userWallets, merklData, tokenInfo);
            setRewards(allRewards);
            console.log("huj pizda dzhigurda");
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch Merkl rewards. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const processUserWallets = async (userWallets, campaignData, tokenInfo) => {
        const allRewards = [];

        // console.log(campaignData);
        let campaignDataAllChains = {};
        for (const chainCampaigns of Object.entries(campaignData)) {
            campaignDataAllChains = Object.assign(campaignDataAllChains,chainCampaigns[1]);
        }
        // console.log(campaignDataAllChains);

        console.log(tokenInfo);

        for (const wallet of userWallets) {
            const rewardDetails = await getLatestMerklReward('user-reward-detailed', wallet.address);
            if (rewardDetails) {
                const walletRewards = await getMerklRewardsFile(rewardDetails.file_id);
                for (const chainId in walletRewards) {
                    const userPositions = await getLatestMerklReward('user-positions', wallet.address, chainId);
                    if (userPositions) {
                        const positionsData = await getMerklRewardsFile(userPositions.file_id);

                        for (const campaignId in walletRewards[chainId].campaignData) {
                            for (const campaignName in walletRewards[chainId].campaignData[campaignId]) {

                                const campaign = walletRewards[chainId].campaignData[campaignId][campaignName];
                                if (parseFloat(campaign.unclaimed) > 0) {

                                    let opportunityData = {};
                                    let opportunityName = '';
                                    let opportunityKey = `${campaign.type}_${campaign.mainParameter}`;

                                    // console.log("Opp:");
                                    // console.log(chainId);
                                    // console.log(campaign.type);
                                    // console.log(campaign.mainParameter);
                                    // console.log(campaignId);
                                    // console.log(`${campaign.type}_${campaign.mainParameter}`);
                                    // console.log("Campaigns API data for current chain:");
                                    // console.log(campaignData[chainId]);
                                    // console.log("Reward data");
                                    // console.log(campaign);

                                    let  mergingKey = opportunityKey + "_" + campaign.token + "_" + wallet.address;
                                    if(campaign.type === 5) {
                                        // Silo
                                        Object.entries(campaignData[chainId]).some((opportunity) => {
                                                Object.entries(opportunity[1]).some((campaign) => {
                                                    if (campaign[0] === campaignId){
                                                        opportunityData = campaignData[chainId][`${opportunity[0]}`][campaignId];
                                                        opportunityName = `Silo ${opportunityData.campaignParameters.symbolTargetToken} Deposit (${opportunityData.campaignParameters.forwarders[0].siloAssetSymbol} Market) (Forwarder?)`;
                                                        mergingKey += `_Silo_${opportunityData.campaignParameters.forwarders[0].siloAssetSymbol}`;
                                                    }
                                                    return campaign[0] === campaignId
                                                })
                                            }
                                        )
                                        opportunityData = campaignData[chainId][opportunityKey];
                                    }
                                    else if(campaign.type === 1) {
                                        // AAVE?
                                        opportunityData = campaignDataAllChains[opportunityKey][campaignId];
                                        opportunityName = `${opportunityData.typeInfo.cardName}`;
                                    }
                                    else if(campaign.type === 7) {
                                        // Monoblue
                                        opportunityData = campaignDataAllChains[opportunityKey][campaignId];
                                        opportunityName = `MorphoBlue Supply ${opportunityData.campaignParameters.symbolBorrowToken}-${opportunityData.campaignParameters.symbolLoanToken} (${getChainName(opportunityData.computeChainId)})`;
                                    } else {
                                        try {
                                            opportunityData = campaignData[chainId][opportunityKey][campaignId];
                                            opportunityName = `${opportunityData.ammName} ${opportunityData.symbolToken0}-${opportunityData.symbolToken1} (Forwarder?)`;
                                        } catch (error){
                                            opportunityName = `*Err* ${opportunityKey}`;
                                        }
                                    }
                                    //opportunityName += ` (${opportunityKey}) (${campaignName})`

                                    let opportunityUserPositionData = {};
                                    opportunityUserPositionData.userTVL = '';
                                    opportunityUserPositionData.inRange = '';
                                    opportunityUserPositionData.apr = '';

                                    try {
                                        opportunityUserPositionData.userTVL = positionsData[opportunityKey].userTVL;
                                    } catch (error){
                                        opportunityUserPositionData.userTVL = `not active`;
                                        //console.log(`Error getting user TVL for ${opportunityKey}`);
                                    }

                                    try {
                                        opportunityUserPositionData.apr = positionsData[opportunityKey].apr['User current APR '];
                                    } catch (error){
                                        opportunityUserPositionData.apr = `not active`;
                                        //console.log(`Error getting user TVL for ${opportunityKey}`);
                                    }

                                    try {
                                        let inRangePositions = 0;
                                        for (const userPosition of positionsData[opportunityKey].userPositions) {
                                            // console.log(userPosition);
                                            if (userPosition.inRangeLiquidity > 0) { inRangePositions++; }
                                        }
                                        opportunityUserPositionData.inRange = `${inRangePositions} of ${positionsData[opportunityKey].userPositions.length}`;
                                    } catch (error){
                                        opportunityUserPositionData.inRange = `not active`;
                                        //console.log(`Error getting user TVL for ${opportunityKey}`);
                                    }

                                    let rewardRecordFound = false;

                                    const unclaimedFloat = parseFloat(campaign.unclaimed) / (10 ** walletRewards[chainId].tokenData[campaign.token].decimals);
                                    const tokenSymbol = walletRewards[chainId].tokenData[campaign.token].symbol;
                                    const tokenDetails = (tokenInfo.prices.find((tokenPrice) => tokenPrice.token === tokenSymbol));
                                    const tokenRate = tokenDetails ? tokenDetails.rate : 0;

                                    for (const userReward of allRewards){
                                        if (userReward.mergingKey === mergingKey){
                                            rewardRecordFound = true;
                                            if (!userReward.unclaimedDetails) {userReward.unclaimedDetails = 0;}
                                            userReward.unclaimedDetails += unclaimedFloat;
                                            userReward.unclaimedUSD = tokenRate * userReward.unclaimedDetails;
                                            userReward.campaignWhitelist = userReward.campaignWhitelist.concat((
                                                opportunityData !== undefined &&
                                                opportunityData.campaignParameters !== undefined &&
                                                opportunityData.campaignParameters.whitelist !== undefined ?
                                                    opportunityData.campaignParameters.whitelist : []));
                                            break;
                                        }
                                    }

                                    if(!rewardRecordFound) {
                                        allRewards.push({
                                            opportunityKey: opportunityKey,
                                            mergingKey: mergingKey,
                                            walletName: wallet.name,
                                            chainName: getChainName(chainId),
                                            token: tokenSymbol,
                                            campaignName: opportunityName,
                                            campaignWhitelist: (
                                                opportunityData !== undefined &&
                                                opportunityData.campaignParameters !== undefined &&
                                                opportunityData.campaignParameters.whitelist !== undefined ?
                                                    opportunityData.campaignParameters.whitelist : []),
                                            userPositionTVL: opportunityUserPositionData.userTVL,
                                            userPositionAPR: opportunityUserPositionData.apr,
                                            userPositionInRange: opportunityUserPositionData.inRange,
                                            unclaimed: unclaimedFloat,
                                            unclaimedDetails: unclaimedFloat,
                                            unclaimedUSD: tokenRate*unclaimedFloat,
                                            //unclaimedDetails: {[walletRewards[chainId].tokenData[campaign.token].symbol] : parseFloat(campaign.unclaimed) / (10 ** walletRewards[chainId].tokenData[campaign.token].decimals)}
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return allRewards;
    };

    // const getCampaignName = (campaignData, chainId, campaignId, campaign) => {
    //     //return campaignData[`${campaign.type}_${campaign.mainParameter}`].name;
    //     const keyPrefix = `${campaign.type}_${campaign.mainParameter}`;
    //     return keyPrefix
    //
    //     if (campaignData[chainId] && campaignData[chainId].campaignData && campaignData[chainId].campaignData[campaignId]) {
    //         const campaignInfo = campaignData[chainId].campaignData[campaignId];
    //         console.log(campaignInfo);
    //         const keyPrefix = `${campaign.type}_${campaign.mainParameter}`;
    //
    //         // Find the key that starts with the keyPrefix
    //         const matchingKey = Object.keys(campaignInfo).find(key => key.startsWith(keyPrefix));
    //
    //         if (matchingKey) {
    //             return campaignInfo[matchingKey].name || matchingKey;
    //         }
    //     }
    //
    //     // Fallback if the campaign name can't be found
    //     return `${campaign.type}_${campaign.mainParameter}`;
    // };

    const columns = useMemo(
        () => [
            {
                Header: 'Wallet Name',
                accessor: 'walletName',
                maxWidth: 1500,
            },
            {
                Header: 'Chain Name',
                accessor: 'chainName',
                maxWidth: 100,
            },
            {
                Header: 'Token',
                accessor: 'token',
                maxWidth: 100,
            },
            {
                Header: 'Opportunity Name',
                accessor: 'campaignName',
                Cell: ({ row }) => {

                    let campaignOutput = [];
                    campaignOutput.push(<span>{row.original.campaignName}</span>);
                    row.original.campaignWhitelist.forEach(whitelist => campaignOutput.push(<a href={"https://app.steer.finance/vault/"+whitelist} target={"_blank"}> (S)</a>))
                    return campaignOutput;
                }
            },
            {
                Header: 'User TVL',
                accessor: 'userPositionTVL',
                maxWidth: 100,
                Cell: ({ value }) => (
                    <div className="">
                        {(parseFloat(value)).toFixed(2)}
                    </div>
                ),
            },
            {
                Header: 'User APR',
                accessor: 'userPositionAPR',
                maxWidth: 100,
                Cell: ({ value }) => (
                    <div className="">
                        {(parseFloat(value)*1000).toFixed(2)}%
                    </div>
                ),
            },
            {
                Header: 'Positions in range',
                accessor: 'userPositionInRange',
                maxWidth: 100,
            },
            {
                Header: 'Unclaimed',
                accessor: 'unclaimedDetails',
                maxWidth: 200,
                Cell: ({ row }) => {
                    let unclaimedRewards = [];
                    //unclaimedRewards.push(<p>{row.original.unclaimed}</p>);
                    unclaimedRewards.push(<p>{row.original.unclaimedDetails.toFixed(6)}</p>);// {row.original.token}
                    // for (const [key, value] of Object.entries(row.original.unclaimedDetails)){
                    //     unclaimedRewards.push(<p>{value} {key}</p>);
                    // }
                    return unclaimedRewards;
                }
            },
            { Header: 'Unclaimed USD', accessor: 'unclaimedUSD', Filter: DefaultColumnFilter, maxWidth: 100,
                Cell: ({ value }) => (
                    <div className="">
                        {value.toFixed(2)}
                    </div>
                ),
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: rewards,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                sortBy: [
                    {
                        id: 'unclaimed',
                        desc: true
                    }
                ]
            }
        },
        useFilters,
        useSortBy
    );

    if (loading) return <div className="h-full flex items-center justify-center">Loading Merkl rewards...</div>;
    if (error) return <div className="h-full flex items-center justify-center">{error}</div>;

    return (
        <div className="h-full flex flex-col">
            <div className="flex-grow overflow-auto">
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className="px-2 py-1 text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50"
                                    style={{ position: 'sticky', top: 0, zIndex: 10, maxWidth: column.maxWidth, }}
                                >
                                    <div className="flex items-center">
                                        {column.render('Header')}
                                        <span className="ml-2">
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <KeyboardArrowDownIcon fontSize="small" />
                                                    : <KeyboardArrowUpIcon fontSize="small" />
                                                : <UnfoldMoreIcon fontSize="small" />}
                                        </span>
                                    </div>
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td
                                        {...cell.getCellProps()}
                                        className="px-2 py-2 text-sm text-gray-500"
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <footer className="bg-gray-100 flex items-center justify-between px-2 py-2">
                <span className="text-sm text-gray-600">
                     Data snapshot
                </span>
                <IconButton>
                    <SyncIcon />
                </IconButton>
            </footer>
        </div>
    );
};

export default MerklRewards;