// src/components/Login.jsx
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { login, getCurrentUser } from '../services/appwriteService';
import { Input, Button, Typography } from '@mui/joy';

const Login = ({ setUser }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await login(email, password);
            const currentUser = await getCurrentUser();
            setUser(currentUser);
            navigate('/');
        } catch (err) {
            setError('Failed to log in. Please check your credentials.');
        }
    };


    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <Typography level="h2" className="mt-6 text-center">
                        Sign in to your account
                    </Typography>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <Input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                placeholder="Email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                            />
                        </div>
                        <div>
                            <Input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                            />
                        </div>
                    </div>

                    {error && <Typography color="danger">{error}</Typography>}

                    <div>
                        <Button
                            type="submit"
                            fullWidth
                            color="primary"
                        >
                            Sign in
                        </Button>
                    </div>
                </form>
                <Typography textAlign="center">
                    .. or <Link to="/signup" className="text-blue-600 hover:underline">register an account</Link>
                </Typography>
            </div>
        </div>
    );
};

export default Login;