// src/MerklCampaigns.jsx
import React, { useMemo, useCallback } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import SyncIcon from '@mui/icons-material/Sync';
import { IconButton } from '@mui/joy';
import { addFavorite, removeFavorite } from './services/appwriteService';
import { getColumns } from './merklCampaignsComponents/TableColumns';
import { useMerklData } from './merklCampaignsComponents/hooks';
import {campaignStartFilterFn, DefaultColumnFilter} from './merklCampaignsComponents/TableFilters';

const MerklCampaigns = () => {
    const {
        data,
        setData,
        tokenInfo,
        loading,
        error,
        snapshotTimestamp,
        syncing,
        favorites,
        setFavorites,
        handleSync,
        totalOpportunities,
        activeOpportunities
    } = useMerklData();

    const handleFavoriteToggle = useCallback(async (id) => {
        try {
            if (favorites[id]) {
                await removeFavorite(id);
                setFavorites(prev => {
                    const newFavorites = { ...prev };
                    delete newFavorites[id];
                    return newFavorites;
                });
            } else {
                await addFavorite(id);
                setFavorites(prev => ({ ...prev, [id]: true }));
            }

            setData(prevData =>
                prevData.map(item =>
                    item.id === id ? { ...item, isFavorite: !item.isFavorite } : item
                )
            );
        } catch (error) {
            console.error('Error toggling favorite:', error);
        }
    }, [favorites, setFavorites, setData]);

    const columns = useMemo(
        () => getColumns(tokenInfo, favorites, handleFavoriteToggle),
        [tokenInfo, favorites, handleFavoriteToggle]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            autoResetSortBy: false,
            autoResetFilters: false,
            initialState: {
                sortBy: [
                    {
                        id: 'apr',
                        desc: true
                    }
                ]
            },
            filterTypes: {
                campaignStart: campaignStartFilterFn,
            },
        },
        useFilters,
        useSortBy
    );

    if (loading) return <div className="h-full flex items-center justify-center">Loading...</div>;
    if (error) return <div className="h-full flex items-center justify-center">{error}</div>;

    return (
        <div className="h-full flex flex-col">
            <div className="flex-grow overflow-auto">
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps()}
                                    className="px-2 py-1 text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50"
                                    style={{ position: 'sticky', top: 0, zIndex: 10, maxWidth: column.maxWidth, }}
                                >
                                    <div className="flex flex-col">
                                        <div className="flex items-center">
                                            <span
                                                {...column.getSortByToggleProps({ title: undefined })}
                                                className="cursor-pointer"
                                            >
                                                {column.render('Header')}
                                            </span>
                                            <span
                                                {...column.getSortByToggleProps({ title: undefined })}
                                                className="ml-2 cursor-pointer"
                                            >
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <KeyboardArrowDownIcon fontSize="small" />
                                                        : <KeyboardArrowUpIcon fontSize="small" />
                                                    : <UnfoldMoreIcon fontSize="small" />}
                                            </span>
                                        </div>
                                        <div className="mt-2">
                                            {column.canFilter ? column.render('Filter') : null}
                                        </div>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td
                                        {...cell.getCellProps()}
                                        className="px-2 py-2 text-sm text-gray-500"
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <footer className="bg-gray-100 flex items-center justify-between px-2 py-2">
                <span className="text-sm text-gray-600">
                     Data snapshot from {new Date(snapshotTimestamp).toLocaleString()} with {activeOpportunities} active opportunities out of {totalOpportunities} total
                </span>
                <IconButton
                    onClick={handleSync}
                    disabled={syncing}
                    className={syncing ? 'animate-spin' : ''}
                    title="Sync data"
                >
                    <SyncIcon />
                </IconButton>
            </footer>
        </div>
    );
};

export default MerklCampaigns;