// src/merklCampaignsComponents/hooks.js
import { useState, useEffect, useCallback } from 'react';
import {
    fetchMerklData,
    syncMerklData,
    fetchFavorites,
    getAllUserStrategyCampaignsMerkl
} from '../services/appwriteService';
import { getChainName } from '../constants/chainConstants';
import { formatDailyRewardTokens } from './formatters';

export const useMerklData = () => {
    const [data, setData] = useState([]);
    const [tokenInfo, setTokenInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [snapshotTimestamp, setSnapshotTimestamp] = useState(null);
    const [syncing, setSyncing] = useState(false);
    const [favorites, setFavorites] = useState({});
    const [totalOpportunities, setTotalOpportunities] = useState(0);
    const [activeOpportunities, setActiveOpportunities] = useState(0);

    const loadData = useCallback(async () => {
        try {
            setLoading(true);
            const [{ campaignData, tokenInfo, snapshotTimestamp }, favoritesData] = await Promise.all([
                fetchMerklData(),
                fetchFavorites()
            ]);
            setTokenInfo(tokenInfo);
            setSnapshotTimestamp(snapshotTimestamp);

            const existingStrategies = await getAllUserStrategyCampaignsMerkl();
            console.log(existingStrategies);

            const favoritesObj = favoritesData.reduce((acc, fav) => {
                acc[fav.opportunity_id] = true;
                return acc;
            }, {});
            setFavorites(favoritesObj);

            if (typeof campaignData === 'object' && campaignData !== null) {
                const totalCount = Object.keys(campaignData).length;
                setTotalOpportunities(totalCount);

                const formattedData = Object.entries(campaignData)
                    .filter(opportunity => opportunity[1].status !== 'past')
                    .map(([key, value]) => {
                        let mostRecentCampaignTimestamp = 0;
                        value.campaigns.active.forEach(campaign => {
                            if (campaign.startTimestamp > mostRecentCampaignTimestamp){mostRecentCampaignTimestamp = campaign.startTimestamp;}
                        });
                        value.campaigns.inactive.forEach(campaign => {
                            if (campaign.startTimestamp > mostRecentCampaignTimestamp){mostRecentCampaignTimestamp = campaign.startTimestamp;}
                        });

                        let strategyDetails = null;
                        existingStrategies.forEach(strategy => {
                            if(strategy.opportunity_id === key){
                                strategyDetails = strategy;
                            }
                        });

                        return (
                            {
                                id: key,
                                isFavorite: !!favoritesObj[key],
                                chainId: value.chainId,
                                chainName: getChainName(value.chainId),
                                strategyDetails: strategyDetails,
                                strategyName: strategyDetails ? strategyDetails.userStrategyNamesMerkl.name : "",
                                name: value.name,
                                tokenIcons: Array.isArray(value.tokenIcons) ? value.tokenIcons.join(', ') : 'N/A',
                                action: value.action,
                                status: value.status,
                                apr: {
                                    total: (value.apr !== undefined && typeof value.apr === 'number') ? value.apr.toFixed(2) : ' - ',
                                    breakdown: value.aprBreakdown || {}
                                },
                                tvl: (value.tvl !== undefined && typeof value.tvl === 'number') ? `$${value.tvl.toFixed(2)}` : ' - ',
                                dailyrewards: value.dailyRewardTokens ? formatDailyRewardTokens(value.dailyRewardTokens, value.chainId, value.dailyrewards) : 'N/A',
                                campaigns: value.status === "soon" ?
                                    (value.campaigns?.inactive || []) :
                                    [...(value.campaigns?.active || []), ...(value.campaigns?.inactive || [])],
                                campaignStart: mostRecentCampaignTimestamp,
                            })
                    });
                setData(formattedData);
                setActiveOpportunities(formattedData.length);
            } else {
                throw new Error('Invalid data format received from API');
            }
        } catch (error) {
            console.error('Error details:', error);
            setError(`Error fetching data: ${error.message}`);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleSync = useCallback(async () => {
        try {
            setSyncing(true);
            await syncMerklData();
            await loadData();
        } catch (error) {
            console.error('Error syncing data:', error);
            setError(`Error syncing data: ${error.message}`);
        } finally {
            setSyncing(false);
        }
    }, [loadData]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        data,
        setData,
        tokenInfo,
        loading,
        error,
        snapshotTimestamp,
        syncing,
        favorites,
        setFavorites,
        handleSync,
        totalOpportunities,
        activeOpportunities
    };
};