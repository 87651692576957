// src/services/appwriteService.js
import { Client, Databases, Query, Functions, Account } from "appwrite";
import axios from 'axios';

const APPWRITE_PROJECT_ID = "6696289300050264079b";
const ASSET_TRACKER_DATABASE_ID = "669788570034a245be45";
const SNAPSHOTS_COLLECTION_ID = "6697abd60037065ea051";
const MERKL_CAMPAIGNS_BUCKET_ID = "6697ab0600272a09bf32";
const MERKL_REWARDS_BUCKET_ID = "merkl-rewards";
const SYNC_FUNCTION_ID = "6697a33d002caedcd94c";
const MERKL_FAVORITES_COLLECTION_ID = "user-favorites-merkl";
const USER_WALLET_ADDRESSES_COLLECTION_ID = "user-wallet-addresses";
const USER_MERKL_REWARDS_COLLECTION_ID = "669be391000a9bd54ccd";
const USER_STRATEGY_NAMES_MERKL_COLLECTION_ID = "66ae25580007d3992936";
const USER_STRATEGY_CAMPAIGNS_MERKL_COLLECTION_ID = "66ae25c50009a85e9a5e";

// Initialize Appwrite client
const client = new Client();
client
    .setEndpoint("https://appwrite.corptech.lv/v1")
    .setProject(APPWRITE_PROJECT_ID);
const databases = new Databases(client);
const functions = new Functions(client);
const account = new Account(client);

// Authentication methods
export const createAccount = async (email, password, name) => {
    try {
        const response = await account.create('unique()', email, password, name);
        return response;
    } catch (error) {
        console.error('Error creating account:', error);
        throw error;
    }
};

export const login = async (email, password) => {
    try {
        const session = await account.createEmailPasswordSession(email, password);
        return session;
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

export const logout = async () => {
    try {
        await account.deleteSession('current');
    } catch (error) {
        console.error('Error logging out:', error);
        throw error;
    }
};

export const getCurrentUser = async () => {
    try {
        const user = await account.get();
        return user;
    } catch (error) {
        console.error('Error getting current user:', error);
        return null;
    }
};

// Snapshot methods
const getLatestSnapshot = async (platform, type) => {
    const snapshots = await databases.listDocuments(ASSET_TRACKER_DATABASE_ID, SNAPSHOTS_COLLECTION_ID,
        [
            Query.orderDesc("$createdAt"),
            Query.equal("platform", platform),
            Query.equal("type", type),
            Query.equal("status", "done"),
            Query.limit(1)
        ]
    );
    return snapshots.documents[0];
};

// File content methods
export const getMerklCampaignFile = async (fileId) => {
    console.log("getMerklCampaignFile " + fileId);
    const response = await axios.get(
        `https://appwrite.corptech.lv/v1/storage/buckets/${MERKL_CAMPAIGNS_BUCKET_ID}/files/${fileId}/view?project=${APPWRITE_PROJECT_ID}`,
        { withCredentials: false }
    );
    return response.data;
};

export const getMerklRewardsFile = async (fileId) => {
    console.log("getMerklRewardsFile " + fileId);
    const response = await axios.get(
        `https://appwrite.corptech.lv/v1/storage/buckets/${MERKL_REWARDS_BUCKET_ID}/files/${fileId}/view?project=${APPWRITE_PROJECT_ID}`,
        { withCredentials: false }
    );
    return response.data;
};

export const fetchMerklLatestApiCampaigns = async () => {
    try {
        const campaignsSnapshot = await getLatestSnapshot("merkl", "api-campaigns");
        return getMerklCampaignFile(campaignsSnapshot.file_id)

    } catch (error) {
        console.error('Error fetching Merkl data:', error);
        throw error;
    }
};

export const fetchMerklLatestTokenInfo = async () => {
    try {
        const tokensSnapshot = await getLatestSnapshot("merkl", "tokens");
        return getMerklCampaignFile(tokensSnapshot.file_id)

    } catch (error) {
        console.error('Error fetching Merkl data:', error);
        throw error;
    }
};

// Merkl data methods
export const fetchMerklData = async () => {
    try {
        const campaignsSnapshot = await getLatestSnapshot("merkl", "campaigns");
        const tokensSnapshot = await getLatestSnapshot("merkl", "tokens");

        const [campaignData, tokenInfo] = await Promise.all([
            getMerklCampaignFile(campaignsSnapshot.file_id),
            getMerklCampaignFile(tokensSnapshot.file_id)
        ]);

        return {
            campaignData,
            tokenInfo,
            snapshotTimestamp: campaignsSnapshot.$createdAt
        };
    } catch (error) {
        console.error('Error fetching Merkl data:', error);
        throw error;
    }
};

export const syncMerklData = async () => {
    try {
        const execution = await functions.createExecution(SYNC_FUNCTION_ID);
        if (execution.status !== 'completed') {
            throw new Error('Sync function did not complete successfully');
        }
    } catch (error) {
        console.error('Error syncing Merkl data:', error);
        throw error;
    }
};

// Favorites methods
export const fetchFavorites = async () => {
    try {
        const response = await databases.listDocuments(
            ASSET_TRACKER_DATABASE_ID,
            MERKL_FAVORITES_COLLECTION_ID,
            [Query.limit(1000)]
        );
        return response.documents;
    } catch (error) {
        console.error('Error fetching favorites:', error);
        throw error;
    }
};

export const addFavorite = async (opportunityId) => {
    try {
        const favorites = await fetchFavorites();
        const favorite = favorites.find(fav => fav.opportunity_id === opportunityId);
        if (!favorite) {
            const response = await databases.createDocument(
                ASSET_TRACKER_DATABASE_ID,
                MERKL_FAVORITES_COLLECTION_ID,
                'unique()',
                {opportunity_id: opportunityId}
            );
            return response;
        }else{
            return null;
        }
    } catch (error) {
        console.error('Error adding favorite:', error);
        throw error;
    }
};

export const removeFavorite = async (opportunityId) => {
    try {
        const favorites = await fetchFavorites();
        const favoriteToRemove = favorites.find(fav => fav.opportunity_id === opportunityId);
        if (favoriteToRemove) {
            await databases.deleteDocument(
                ASSET_TRACKER_DATABASE_ID,
                MERKL_FAVORITES_COLLECTION_ID,
                favoriteToRemove.$id
            );
        }
    } catch (error) {
        console.error('Error removing favorite:', error);
        throw error;
    }
};

// User wallet methods
export const fetchUserWalletAddresses = async () => {
    try {
        const response = await databases.listDocuments(
            ASSET_TRACKER_DATABASE_ID,
            USER_WALLET_ADDRESSES_COLLECTION_ID
        );
        return response.documents;
    } catch (error) {
        console.error('Error fetching user wallet addresses:', error);
        throw error;
    }
};

export const addWalletAddress = async (address, chains, name) => {
    try {
        const response = await databases.createDocument(
            ASSET_TRACKER_DATABASE_ID,
            USER_WALLET_ADDRESSES_COLLECTION_ID,
            'unique()',
            { address, chains, name }
        );
        return response;
    } catch (error) {
        console.error('Error adding wallet address:', error);
        throw error;
    }
};

export const updateWalletAddress = async (id, address, chains, name) => {
    try {
        const response = await databases.updateDocument(
            ASSET_TRACKER_DATABASE_ID,
            USER_WALLET_ADDRESSES_COLLECTION_ID,
            id,
            { address, chains, name }
        );
        return response;
    } catch (error) {
        console.error('Error updating wallet address:', error);
        throw error;
    }
};

export const deleteWalletAddress = async (id) => {
    try {
        await databases.deleteDocument(
            ASSET_TRACKER_DATABASE_ID,
            USER_WALLET_ADDRESSES_COLLECTION_ID,
            id
        );
    } catch (error) {
        console.error('Error deleting wallet address:', error);
        throw error;
    }
};

// Merkl Rewards methods
export const getLatestMerklReward = async (type, address, chainId = null) => {
    let queries = [
        Query.equal('type', type),
        Query.equal('status', 'done'),
        Query.equal('address', address),
        Query.orderDesc('$createdAt'),
        Query.limit(1)
    ];

    if (chainId) {
        queries.push(Query.equal('merkl-chain-id', parseInt(chainId)));
    }

    const response = await databases.listDocuments(
        ASSET_TRACKER_DATABASE_ID,
        USER_MERKL_REWARDS_COLLECTION_ID,
        queries
    );

    return response.documents[0];
};

export const getUserWalletsForMerklRewards = async () => {
    const response = await databases.listDocuments(
        ASSET_TRACKER_DATABASE_ID,
        USER_WALLET_ADDRESSES_COLLECTION_ID,
        [Query.equal('pool-merkl-rewards', true)]
    );
    return response.documents;
};

export const listUserStrategyNamesMerkl = async () => {
    try {
        const response = await databases.listDocuments(
            ASSET_TRACKER_DATABASE_ID,
            USER_STRATEGY_NAMES_MERKL_COLLECTION_ID
        );
        return response.documents;
    } catch (error) {
        console.error('Error listing userStrategyNamesMerkl:', error);
        throw error;
    }
};

export const createUserStrategyNamesMerkl = async (name) => {
    try {
        const response = await databases.createDocument(
            ASSET_TRACKER_DATABASE_ID,
            USER_STRATEGY_NAMES_MERKL_COLLECTION_ID,
            'unique()',
            { name }
        );
        return response;
    } catch (error) {
        console.error('Error creating userStrategyNamesMerkl:', error);
        throw error;
    }
};

export const deleteUserStrategyNamesMerkl = async (id) => {
    try {
        await databases.deleteDocument(
            ASSET_TRACKER_DATABASE_ID,
            USER_STRATEGY_NAMES_MERKL_COLLECTION_ID,
            id
        );
    } catch (error) {
        console.error('Error deleting userStrategyNamesMerkl:', error);
        throw error;
    }
};

export const updateUserStrategyNameMerkl = async (id, name) => {
    try {
        const response = await databases.updateDocument(
            ASSET_TRACKER_DATABASE_ID,
            USER_STRATEGY_NAMES_MERKL_COLLECTION_ID,
            id,
            { name }
        );
        return response;
    } catch (error) {
        console.error('Error updating userStrategyNamesMerkl:', error);
        throw error;
    }
};

// New functions for userStrategyCampaignsMerkl

export const addUserStrategyCampaignMerkl = async (userStrategyNamesMerklId, opportunityId) => {
    try {
        const response = await databases.createDocument(
            ASSET_TRACKER_DATABASE_ID,
            USER_STRATEGY_CAMPAIGNS_MERKL_COLLECTION_ID,
            'unique()',
            {
                userStrategyNamesMerkl: userStrategyNamesMerklId,
                opportunity_id: opportunityId
            }
        );
        return response;
    } catch (error) {
        console.error('Error adding userStrategyCampaignMerkl:', error);
        throw error;
    }
};

export const deleteUserStrategyCampaignMerkl = async (id) => {
    try {
        await databases.deleteDocument(
            ASSET_TRACKER_DATABASE_ID,
            USER_STRATEGY_CAMPAIGNS_MERKL_COLLECTION_ID,
            id
        );
    } catch (error) {
        console.error('Error deleting userStrategyCampaignMerkl:', error);
        throw error;
    }
};

export const getUserStrategyCampaignsMerkl = async (strategyNameId, opportunityId) => {
    try {
        const response = await databases.listDocuments(
            ASSET_TRACKER_DATABASE_ID,
            USER_STRATEGY_CAMPAIGNS_MERKL_COLLECTION_ID,
            [
                Query.equal('opportunity_id', [opportunityId])
            ]
        );
        return response.documents;
    } catch (error) {
        console.error('Error getting userStrategyCampaignsMerkl:', error);
        throw error;
    }
};

export const getAllUserStrategyCampaignsMerkl = async () => {
    try {
        const response = await databases.listDocuments(
            ASSET_TRACKER_DATABASE_ID,
            USER_STRATEGY_CAMPAIGNS_MERKL_COLLECTION_ID,
        );
        return response.documents;
    } catch (error) {
        console.error('Error getting userStrategyCampaignsMerkl:', error);
        throw error;
    }
};