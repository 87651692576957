// src/MainMenu.jsx
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from './services/appwriteService';
import { Button, Typography } from '@mui/joy';

const MainMenu = ({ user, setUser }) => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout();
            setUser(null);
            navigate('/login');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <nav className="bg-gray-800 p-4 sticky top-0 z-10">
            <div className="container mx-auto flex justify-between items-center">
                <ul className="flex space-x-4">
                    {user && (
                        <>
                            <li>
                                <Link to="/" className="text-white hover:text-gray-300">
                                    Merkl Campaigns
                                </Link>
                            </li>
                            <li>
                                <Link to="/strategy" className="text-white hover:text-gray-300">
                                    Strategy
                                </Link>
                            </li>
                            <li>
                                <Link to="/user-wallets" className="text-white hover:text-gray-300">
                                    User Wallets
                                </Link>
                            </li>
                            <li>
                                <Link to="/merkl-rewards" className="text-white hover:text-gray-300">
                                    Merkl Rewards
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
                <div>
                    {user && (
                        <div className="flex items-center space-x-4">
                            <Typography color="neutral" className="text-white">Welcome, {user.name}</Typography>
                            <Button
                                onClick={handleLogout}
                                color="neutral"
                                size="sm"
                                variant="soft"
                            >
                                Logout
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default MainMenu;