// src/UserWallets.jsx
import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Checkbox, Input, IconButton, ModalClose, Typography, ModalDialog, Sheet, List, ListItem, Box, Grid } from '@mui/joy';

import EditIcon from '@mui/icons-material/Edit';
import { fetchUserWalletAddresses, addWalletAddress, updateWalletAddress, deleteWalletAddress } from './services/appwriteService';
import { chains } from './constants/chainConstants';

const UserWallets = () => {
    const [wallets, setWallets] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingWallet, setEditingWallet] = useState(null);
    const [address, setAddress] = useState('');
    const [name, setName] = useState('');
    const [selectedChains, setSelectedChains] = useState([]);

    useEffect(() => {
        fetchWallets();
    }, []);

    const fetchWallets = async () => {
        try {
            const fetchedWallets = await fetchUserWalletAddresses();
            setWallets(fetchedWallets);
        } catch (error) {
            console.error('Error fetching wallets:', error);
        }
    };

    const handleOpenModal = (wallet = null) => {
        if (wallet) {
            setEditingWallet(wallet);
            setAddress(wallet.address);
            setName(wallet.name);
            setSelectedChains(wallet.chains);
        } else {
            setEditingWallet(null);
            setAddress('');
            setName('');
            setSelectedChains([]);
        }
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditingWallet(null);
        setAddress('');
        setName('');
        setSelectedChains([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingWallet) {
                await updateWalletAddress(editingWallet.$id, address, selectedChains, name);
            } else {
                await addWalletAddress(address, selectedChains, name);
            }
            fetchWallets();
            handleCloseModal();
        } catch (error) {
            console.error('Error saving wallet:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteWalletAddress(id);
            handleCloseModal();
            fetchWallets();
        } catch (error) {
            console.error('Error deleting wallet:', error);
        }
    };

    const handleChainToggle = (chain) => {
        setSelectedChains(prev =>
            prev.includes(chain)
                ? prev.filter(c => c !== chain)
                : [...prev, chain]
        );
    };

    return (
        <div className="container mx-auto p-4">
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid xs={6}>
                    <Typography level="h3">User Wallets</Typography>
                </Grid>
                <Grid xs={6} className="text-right">
                    <Button variant="outlined" size="sm" onClick={() => handleOpenModal()} className="mb-4">Add New</Button>
                </Grid>
            </Grid>


            <Table>
                <thead>
                <tr>
                    <th style={{ width: '350px' }}>Address</th>
                    <th>Chains</th>
                    <th style={{ width: '64px' }}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {wallets.map(wallet => (
                    <tr key={wallet.$id}>
                        <td>
                            <Typography>{wallet.name}</Typography>
                            <Typography color="neutral">{wallet.address}</Typography>
                        </td>
                        <td>{wallet.chains.join(', ')}</td>
                        <td>
                            <IconButton onClick={() => handleOpenModal(wallet)}>
                                <EditIcon />
                            </IconButton>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 800,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                <form onSubmit={handleSubmit} className="p-4 bg-white rounded-lg">
                    <h2 className="text-xl font-bold mb-4">
                        {editingWallet ? 'Edit Wallet' : 'Add New Wallet'}
                    </h2>
                    <Input
                        placeholder="Wallet Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                        fullWidth
                        className="mb-4"
                    />
                    <Input
                        placeholder="Wallet Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        fullWidth
                        className="mb-4"
                    />
                    <div className="mb-4">
                        <h3 className="font-bold mb-2">Chains</h3>
                        <List
                            orientation="horizontal"
                            wrap
                            sx={{
                                '--List-gap': '8px',
                                '--ListItem-radius': '10px',
                            }}>
                        {Object.values(chains).map((chain) => (
                            <ListItem key={chain}>
                            <Checkbox
                                key={chain}
                                label={chain}
                                size="sm"
                                overlay
                                disableIcon

                                color="neutral"
                                checked={selectedChains.includes(chain)}
                                onChange={() => handleChainToggle(chain)}
                            />
                            </ListItem>
                        ))}
                        </List>
                    </div>

                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={6}>
                            {editingWallet && (
                                <Button onClick={() => handleDelete(editingWallet.$id)} color="danger" variant="outlined">
                                    Delete
                                </Button>
                            )}
                        </Grid>
                        <Grid xs={6}>
                            <Box
                                sx={{
                                    mt: 1,
                                    display: 'flex',
                                    gap: 1,
                                    flexDirection: { xs: 'column', sm: 'row-reverse' },
                                }}
                            >
                                <Button type="submit">Save</Button>
                                <Button onClick={handleCloseModal} color="neutral" variant="outlined">Cancel</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
                </Sheet>
            </Modal>
        </div>
    );
};

export default UserWallets;